// Signature
console.log(
    '%c \u2727 David\u00A0Lu \u2727 ',
    `
    text-align: center;
    font-family: Brush Script MT, Brush Script, Brush Script MT Italic, sans-serif;
    font-weight: bold;
    font-size: 2rem;
    color: #ffffff;
    background: #ed1b2f;
    text-shadow: 2px 2px 2px #00000080;
    `
)
